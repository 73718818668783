import CheckIcon from "../images/icon/check.svg";
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/general-fabrics-header-image.jpg";
import Slide1 from "../images/slides/general-fabrics/01-buyer-search-fabrics.png";
import Slide2 from "../images/slides/general-fabrics/02-buyer-fabrics-products-tab.png";
import Slide3 from "../images/slides/general-fabrics/slide03.png";
import Slide4 from "../images/slides/general-fabrics/slide04.png";
import Slide5 from "../images/slides/general-fabrics/slide05.png";
import Slide6 from "../images/slides/general-fabrics/06-rfq-fabric.png";
import Slide7 from "../images/slides/general-fabrics/preview-rfq-fabric-1.png";
import Slide8 from "../images/slides/general-fabrics/slide08.png";
import Slide9 from "../images/slides/general-fabrics/slide09.png";


export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("general-fabrics.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("general-fabrics.topSubtitle"),
        colorClass: "colorLight",
      },
      cta: {
        text: t("general-fabrics.joinFree"),
        url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(t("general-fabrics.signupLink")),
        type: "mainColor",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("general-fabrics.imgAlt"),
      },
    },
    pageContent: [
      {
        info: {
          title: {
            text: t("general-fabrics.findSupplier"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [
              t("general-fabrics.accessNetwork"),
              t("general-fabrics.findBest"),
              t("general-fabrics.viewProfiles"),
              t("general-fabrics.saveTime"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: true,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: true,
          customPBText: true,
          images: [Slide1, Slide2, Slide3],
        },
        isTextFirst: true,
        cta: {
          text: t("general-fabrics.exploreNetwork"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
      {
        info: {
          title: {
            text: t("face-masks-and-medical-wear.exploreShowrooms"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [
              t("general-fabrics.viewSamples"),
              t("general-fabrics.accessCatalogues"),
              t("general-fabrics.fineFilters"),
              t("general-fabrics.addFavourites"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: false,
          topDiagonal: false,
          backgroundGrey: false,
          customPB: false,
          customPBText: true,
          images: [Slide4, Slide5],
        },
        isTextFirst: false,
        cta: {
          text: t("general-fabrics.exploreProducts"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
      {
        info: {
          title: {
            text: t("general-fabrics.requestQuotes"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [
              t("general-fabrics.createRFQs"),
              t("general-fabrics.receiveOffers"),
              t("general-fabrics.manageSelection"),
              t("general-fabrics.reduceTimes"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: false,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: true,
          customPBText: true,
          images: [Slide6, Slide7],
        },
        isTextFirst: true,
        cta: {
          text: t("general-fabrics.getQuotes"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
      {
        info: {
          title: {
            text: t("general-fabrics.sourceSustainably"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [
              t("general-fabrics.sustainableSuppliers"),
              t("general-fabrics.complianceCertificates"),
              t("general-fabrics.validityOfYourSuppliers"),
              t("general-fabrics.saveTimeReviewing"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: false,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: true,
          customPBText: true,
          images: [Slide8, Slide9],
        },
        isTextFirst: false,
        cta: {
          text: t("general-fabrics.searchCertificates"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
    ],


    aboutUs: {
      title: {
        text: t("face-masks-and-medical-wear.aboutUs"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("face-masks-and-medical-wear.aboutUsText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
  };
};

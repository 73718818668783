/**
 * Foursource page
 * https://foursource.com/general-fabrics
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/general-fabrics";
import { associates } from "../content/pages/associatesPrincipal";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import Associates from "../components/_page/associates";
import SideBySide from "../components/sideBySide";
import TitleSeparator from "../components/titleSeparator";

class generalFabricsPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.associates = associates(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName="general-fabrics" whiteMenu={false} noPopUp={true}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          {this.text.pageContent.map((content, i) => (
            <SideBySide key={i} info={content.info} isTextFirst={content.isTextFirst} cta={content.cta}></SideBySide>
          ))}
          <div className="paddingSidesBigger pt-50 pb-50 text-center">
            <TitleSeparator info={this.text.aboutUs}></TitleSeparator>
          </div>
          <div className="pt-75 backgroundGrey topDiagonal">
            <Associates info={this.associates.cardsInfo}></Associates>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(generalFabricsPage);
